<template>
  <div class="deploy">
    <h2 class="g-title title">
      <span>{{ $t("deploy.title1") }}</span>
      <span class="title__em">{{ $t("deploy.title2") }}</span>
      <span>{{ $t("deploy.title3") }}</span>
    </h2>
    <div class="list">
      <DeployItem
        class="list__item"
        v-for="(item, index) in suggestions"
        :key="index"
        :item="item"
      />
    </div>
  </div>
</template>

<script>
import DeployItem from '@/views/BusinessCard/DeployItem.vue';

export default {
  name: 'Deploy',
  components: { DeployItem },
  data() {
    return {
      suggestions: [
        {
          title: this.$t('deploy.client1'),
          checks: [
            this.$t('deploy.check11'),
            this.$t('deploy.check12'),
            this.$t('deploy.check13'),
            this.$t('deploy.check14'),
            this.$t('deploy.check15'),
            this.$t('deploy.check16'),
          ],
          suggest: [
            {
              label: this.$t('deploy.virtualCard'),
              type: this.$t('deploy.area1'),
              icon: [1],
            },
          ],
        },
        {
          title: this.$t('deploy.client2'),
          checks: [
            this.$t('deploy.check21'),
            this.$t('deploy.check22'),
            this.$t('deploy.check23'),
            this.$t('deploy.check24'),
            this.$t('deploy.check25'),
            this.$t('deploy.check26'),
          ],
          suggest: [
            {
              label: this.$t('deploy.virtualCard'),
              type: this.$t('deploy.area21'),
              icon: [1],
            },
            {
              label: this.$t('deploy.genericCard'),
              type: this.$t('deploy.area22'),
              icon: [1],
            },
            {
              label: this.$t('deploy.genericCard'),
              type: this.$t('deploy.area23'),
              icon: [2, 3],
            },
          ],
        },
        {
          title: this.$t('deploy.client3'),
          checks: [
            this.$t('deploy.check31'),
            this.$t('deploy.check32'),
            this.$t('deploy.check33'),
            this.$t('deploy.check34'),
            this.$t('deploy.check35'),
            this.$t('deploy.check36'),
          ],
          suggest: [
            {
              label: this.$t('deploy.virtualCard'),
              type: this.$t('deploy.area31'),
              icon: [1],
            },
            {
              label: this.$t('deploy.genericCard'),
              type: this.$t('deploy.area32'),
              icon: [1, 2],
            },
          ],
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.deploy {
  padding-top: 160px;
}

.title__em {
  color: var(--color-theme);
}

.title {
  max-width: 700px;
  line-height: 60px;
  @media (max-width: 1080px) {
    margin: 0 auto;
    text-align: center;
  }
}

.list {
  margin-top: 80px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @media (max-width: 1080px) {
    display: initial;
    .list__item {
      width: 380px;
      max-width: 100%;
      margin: 80px auto 0;
    }
  }
  .list__item {
    margin-top: 40px;
    white-space: nowrap;
  }
}
</style>

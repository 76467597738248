<template>
  <div class="card-solution g-container" id="card-solution">
    <h2 class="g-title">{{ $t("solution.title") }}</h2>
    <div class="tip1">{{ $t("solution.titleTip1") }}</div>
    <div class="tip2">{{ $t("solution.titleTip2") }}</div>
    <div class="cards">
      <img class="img--card" src="@/assets/card1_new.svg" alt="" />
      <CardTypeItem
        :title="cards[0].title"
        :content="cards[0].content"
        :list="cards[0].list"
      />
      <CardTypeItem
        :title="cards[1].title"
        :content="cards[1].content"
        :list="cards[1].list"
        :union="false"
      />
      <img class="img--card" src="@/assets/card2.png" alt="" />
      <img class="img--card" src="@/assets/card3.png" alt="" />
      <CardTypeItem
        :title="cards[2].title"
        :content="cards[2].content"
        :list="cards[2].list"
        :union="false"
      />
    </div>
    <!-- <RouterLink class="g-link-clear btn--more" to="/card">
      {{ $t("solution.learnMore") }}
    </RouterLink> -->
  </div>
</template>

<script>
import CardTypeItem from '@/components/CardTypeItem.vue';

export default {
  name: 'CardSolution',
  components: { CardTypeItem },
  data() {
    return {
      cards: [
        {
          title: this.$t('solution.genericCard'),
          content: this.$t('solution.genericCardDes'),
          list: [
            this.$t('solution.genericCardList1'),
            this.$t('solution.genericCardList2'),
            this.$t('solution.genericCardList3'),
            this.$t('solution.genericCardList4'),
            this.$t('solution.genericCardList5'),
          ],
        },
        {
          title: this.$t('solution.fullBrandCard'),
          content: this.$t('solution.fillBrandCardDes'),
          list: [
            this.$t('solution.fillBrandCardList1'),
            this.$t('solution.fillBrandCardList2'),
            this.$t('solution.fillBrandCardList3'),
            this.$t('solution.fillBrandCardList4'),
            this.$t('solution.fillBrandCardList5'),
            this.$t('solution.fillBrandCardList6'),
            this.$t('solution.fillBrandCardList7'),
          ],
        },
        {
          title: this.$t('solution.virtualCard'),
          content: this.$t('solution.virtualCardDes'),
          list: [
            this.$t('solution.virtualCardList1'),
            this.$t('solution.virtualCardList2'),
            this.$t('solution.virtualCardList3'),
            this.$t('solution.virtualCardList4'),
            this.$t('solution.virtualCardList5'),
            this.$t('solution.virtualCardList6'),
          ],
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/var.scss";

.card-solution {
  padding-top: 160px;
  padding-bottom: 200px;
  text-align: center;
  margin: 0 auto;
}

.tip1 {
  margin-top: 20px;
  font-size: 24px;
  line-height: 28px;
  color: var(--color-content);
}

.tip2 {
  margin: 60px auto 0;
  padding: 20px 64px;
  display: inline-block;
  line-height: 24px;
  font-size: 14px;
  background: var(--background-theme);
  color: var(--color-theme);
}

.img--card {
  width: 100%;
  max-width: 540px;
  border-radius: 20px;
}

.cards {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  margin-top: 100px;
  grid-row-gap: 120px;
  grid-column-gap: 100px;

  @media (max-width: 960px) {
    grid-template-columns: 1fr;
    justify-items: center;
    grid-row-gap: 0;

    .img--card {
      &:not(:first-child) {
        margin-top: 100px;
      }
    }

    & > div {
      margin-top: 80px;
      max-width: 540px;
    }

    & > * {
      &:nth-child(3) {
        grid-row: 4;
      }
      &:nth-child(4) {
        grid-row: 3;
      }
    }
  }
}

.btn--more {
  margin-top: 120px;
  display: inline-block;
  background: var(--color-theme);
  border-radius: 30px;
  line-height: 60px;
  padding: 0 70px;
  color: #fff;
  font-size: 18px;
  cursor: pointer;
  transition: opacity 0.3s;
  opacity: 0.8;
  &:hover {
    opacity: 1;
  }
}
</style>

<template>
  <div>
    <img src="@/assets/fintech1.png" alt="" class="icon">
    <div class="title">{{ item.title }}</div>
    <div class="check" v-for="(check, index) in item.checks" :key="index">
      <img class="check__img" src="@/assets/check.png" alt="">
      <span class="check__label">{{ check }}</span>
    </div>
    <div class="suggest">{{ $t('deploy.suggest') }}</div>
    <table class="suggest-table">
      <tr v-for="(suggest, index) in item.suggest" :key="`suggest${index}`">
        <td>{{ suggest.label }}</td>
        <td>{{ suggest.type }}</td>
        <td>
          <img
            v-if="suggest.icon.includes(1)"
            class="suggest-item__img"
            src="@/assets/bank1.png"
            alt="">
          <img
            v-if="suggest.icon.includes(2)"
            class="suggest-item__img"
            src="@/assets/bank2.png"
            alt="">
          <img
            v-if="suggest.icon.includes(3)"
            class="suggest-item__img"
            src="@/assets/bank3.png"
            alt="">
        </td>
      </tr>
    </table>
  </div>
</template>

<script>

export default {
  name: 'DeployItem',
  props: {
    item: {
      type: Object,
      require: true,
    },
  },
};
</script>

<style lang="scss" scoped>
  .icon {
    height: 60px;
  }

  .title {
    margin-top: 40px;
    margin-bottom: 30px;
    font-size: 22px;
    line-height: 28px;
  }

  .check {
    & + & {
      margin-top: 10px;
    }
  }

  .check__img {
    height: 12px;
  }

  .check__label {
    margin-left: 20px;
    font-size: 14px;
    line-height: 20px;
    color: var(--color-content);
  }

  .suggest {
    margin-top: 40px;
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 28px;
  }

  .suggest-table {
    color: var(--color-content);

    td {
      padding-right: 30px;
    }
  }

  .suggest-item__img {
    & + & {
      margin-left: 34px;
    }
  }
</style>

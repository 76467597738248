<template>
  <div class="banner">
    <div class="g-container wrapper">
      <div class="top">
        <div class="banner__left">
          <h2 class="g-title title">{{ $t("cardSolutions.title") }}</h2>
          <div class="tip">{{ $t("cardSolutions.tip") }}</div>
        </div>
        <div class="banner__right">
          <img src="@/assets/access.png" alt="" />
        </div>
      </div>
      <CardSolution />
      <CardTable class="l-card-table" />
    </div>
  </div>
</template>

<script>
import CardTable from '@/views/BusinessCard/CardTable.vue';
import CardSolution from '@/views/BusinessCard/CardSolution.vue';

export default {
  name: 'Banner',
  components: { CardTable, CardSolution },
};
</script>

<style lang="scss" scoped>
.banner {
  background-color: rgba(245, 249, 251, 1);
  background-image: url("~@/assets/security-bg.svg");
  background-repeat: no-repeat;
  background-position: right;

  @media (max-width: 1080px) {
    background-image: none;
  }
}

.wrapper {
  position: relative;
  padding-top: 183px;
  padding-bottom: 167px;
}

.top {
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 1080px) {
    grid-template-columns: 1fr;
    justify-items: center;
  }
}

.banner__left {
  max-width: 500px;
}

.banner__right {
  img {
    max-width: 100%;
  }
}

.title {
  font-size: 62px;
  font-weight: 500;
  line-height: 87px;
  font-family: NoumenaFont-Bold, PingFang SC, Microsoft YaHei, SimHei,
    sans-serif;
}

.tip {
  margin-top: 20px;
  max-width: 500px;
  font-size: 16px;
  line-height: 24px;
  color: var(--color-content);
}

.l-card-table {
  margin-top: 200px;
}

.applyCard {
  margin-top: 20px;
  text-align: center;
  .btn--contact {
    border: 1px solid #0045ff;
    background: #0045ff;
    color: #fff;
    box-sizing: border-box;
    display: inline-block;
    margin-top: 40px;
    padding: 0 40px;
    height: 60px;
    line-height: 58px;
    text-align: center;
    border-radius: 30px;
    cursor: pointer;
    font-size: 18px;
    opacity: 0.8;
    transition: opacity 0.3s;
    &:hover {
      opacity: 1;
    }
  }
}
</style>

<template>
  <div class="customize">
    <div class="tip1">{{ $t('customize.tip1') }}</div>
    <h2 class="g-title title">
      <span>{{ $t('customize.title1') }}</span>
      <span class="title__em">{{ $t('customize.title2') }}</span>
      <span>{{ $t('customize.title3') }}</span>
    </h2>
    <div class="tip2">{{ $t('customize.tip2') }}</div>
    <img class="card" src="@/assets/cards.png" alt="cards">
    <br>
    <div class="choose">
      <div class="choose__title">{{ $t('customize.choose') }}</div>
      <div class="choose__item">
        <div class="choose__item__dec" style="background: #A0A0A0;"></div>
        <div class="choose__item__label">{{ $t('customize.silver') }}</div>
      </div>
      <div class="choose__item">
        <div class="choose__item__dec" style="background: #757575;"></div>
        <div class="choose__item__label">{{ $t('customize.brushed') }}</div>
      </div>
      <div class="choose__item">
        <div class="choose__item__dec" style="background:#000000; "></div>
        <div class="choose__item__label">{{ $t('customize.black') }}</div>
      </div>
      <div class="choose__item">
        <div class="choose__item__dec" style="background: #D2894B;"></div>
        <div class="choose__item__label">{{ $t('customize.gold') }}</div>
      </div>
      <div class="choose__item">
        <div class="choose__item__dec" style="background: #DADADA;"></div>
        <div class="choose__item__label">{{ $t('customize.white') }}</div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Customize',
};
</script>

<style lang="scss" scoped>
  .customize {
    padding-top: 160px;
    padding-bottom: 180px;
    text-align: center;
    font-size: 0;
  }

  .tip1 {
    font-size: 16px;
    line-height: 28px;
    color: var(--color-content);
  }

  .title {
    margin-top: 10px;
  }

  .title__em {
    color: var(--color-theme);
  }

  .tip2 {
    max-width: 900px;
    margin: 30px auto 0;
    font-size: 24px;
    line-height: 30px;
    color: var(--color-content);
  }

  .card {
    margin-top: 120px;
    width: 100%;
    max-width: 500px;
  }

  .choose {
    margin-top: 60px;
    display: inline-block;
  }

  .choose__title {
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 28px;
  }

  .choose__item {
    position: relative;
    display: inline-block;
    &:not(:last-child) {
      margin-right: 40px;
    }
  }

  .choose__item__dec {
    width: 50px;
    height: 10px;
    border-radius: 2px;
  }

  .choose__item__label {
    position: absolute;
    margin-top: 14px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 12px;
    line-height: 14px;
    color: var(--color-tip);
    white-space: nowrap;
  }
</style>

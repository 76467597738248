<template>
  <table class="card-table">
    <tr>
      <th></th>
      <th class="g-font-mid">{{ $t('cardSolutions.genericCard') }}</th>
      <th class="g-font-mid">{{ $t('cardSolutions.fullBrandCard') }}</th>
      <th class="g-font-mid">{{ $t('cardSolutions.virtualCard') }}</th>
    </tr>
    <tr>
      <td>{{ $t('cardSolutions.cardType') }}</td>
      <td>
        <div>{{ $t('cardSolutions.classicPremium') }}</div>
        <div>{{ $t('cardSolutions.classic') }}</div>
      </td>
      <td>
        <div>{{ $t('cardSolutions.classic') }}</div>
        <div>{{ $t('cardSolutions.classic') }}</div>
      </td>
      <td>
        <div>{{ $t('cardSolutions.virtual') }}</div>
        <div>{{ $t('cardSolutions.virtual') }}</div>
      </td>
    </tr>
    <tr>
      <td>{{ $t('cardSolutions.cardSchemeSupport') }}</td>
      <td>
        <div>
          <img src="@/assets/bank2.png" alt="">
          <img class="l-img" src="@/assets/bank3.png" alt="">
        </div>
        <div>
          <img src="@/assets/bank1.png" alt="">
        </div>
      </td>
      <td>
        <div>
          <img src="@/assets/bank2.png" alt="">
        </div>
        <div>
          <img src="@/assets/bank1.png" alt="">
        </div>
      </td>
      <td>
        <div>
          <img src="@/assets/bank2.png" alt="">
        </div>
        <div>
          <img src="@/assets/bank1.png" alt="">
        </div>
      </td>
    </tr>
    <tr>
      <td>{{ $t('cardSolutions.regionOver') }}</td>
      <td>
        <div>{{ $t('cardSolutions.global') }}</div>
        <div>{{ $t('cardSolutions.global') }}</div>
      </td>
      <td>
        <div>{{ $t('cardSolutions.euOnly') }}</div>
        <div>{{ $t('cardSolutions.global') }}</div>
      </td>
      <td>
        <div>{{ $t('cardSolutions.global') }}</div>
        <div>{{ $t('cardSolutions.global') }}</div>
      </td>
    </tr>
    <tr>
      <td>{{ $t('cardSolutions.setUp') }}</td>
      <td>
        <div>{{ $t('cardSolutions.setUp1') }}</div>
        <div>{{ $t('cardSolutions.setUp2') }}</div>
      </td>
      <td>
        <div>{{ $t('cardSolutions.setUp3') }}</div>
        <div>{{ $t('cardSolutions.setUp4') }}</div>
      </td>
      <td>
        <div>{{ $t('cardSolutions.setUp5') }}</div>
        <div>{{ $t('cardSolutions.setUp6') }}</div>
      </td>
    </tr>
    <tr>
      <td>{{ $t('cardSolutions.apiSupport') }}</td>
      <td>
        <div>{{ $t('cardSolutions.no') }}</div>
        <div>{{ $t('cardSolutions.yes') }}</div>
      </td>
      <td>
        <div>{{ $t('cardSolutions.yes') }}</div>
        <div>{{ $t('cardSolutions.yes') }}</div>
      </td>
      <td>
        <div>{{ $t('cardSolutions.yes') }}</div>
        <div>{{ $t('cardSolutions.yes') }}</div>
      </td>
    </tr>
    <tr>
      <td>{{ $t('cardSolutions.pciSupport') }}</td>
      <td>
        <div>{{ $t('cardSolutions.no') }}</div>
        <div>{{ $t('cardSolutions.yes') }}</div>
      </td>
      <td>
        <div>{{ $t('cardSolutions.yes') }}</div>
        <div>{{ $t('cardSolutions.yes') }}</div>
      </td>
      <td>
        <div>{{ $t('cardSolutions.yes') }}</div>
        <div>{{ $t('cardSolutions.yes') }}</div>
      </td>
    </tr>
    <tr>
      <td>{{ $t('cardSolutions.cardManagementPortal') }}</td>
      <td>
        <div>{{ $t('cardSolutions.no') }}</div>
        <div>{{ $t('cardSolutions.no') }}</div>
      </td>
      <td>
        <div>{{ $t('cardSolutions.no') }}</div>
        <div>{{ $t('cardSolutions.no') }}</div>
      </td>
      <td>
        <div>{{ $t('cardSolutions.no') }}</div>
        <div>{{ $t('cardSolutions.no') }}</div>
      </td>
    </tr>
    <tr>
      <td>{{ $t('cardSolutions.classicPremium') }}</td>
      <td>
        <div>{{ $t('cardSolutions.no') }}</div>
        <div>{{ $t('cardSolutions.no') }}</div>
      </td>
      <td>
        <div>{{ $t('cardSolutions.no') }}</div>
        <div>{{ $t('cardSolutions.no') }}</div>
      </td>
      <td>
        <div>{{ $t('cardSolutions.no') }}</div>
        <div>{{ $t('cardSolutions.no') }}</div>
      </td>
    </tr>
  </table>
</template>

<script>

export default {
  name: 'CardTable',
};
</script>

<style lang="scss" scoped>
  .card-table {
    width: 100%;
    border-collapse: collapse;
  }

  th {
    font-size: 20px;
  }

  tr + tr {
    border-top: 1px solid var(--border-common);
  }

  th, td {
    text-align: left;
    height: 68px;
    padding-left: 30px;

    & + & {
      border-left: 1px solid var(--border-common);
    }

    &:first-child {
      padding-left: 34px;
      width: 234px;
    }
  }

  td {
    font-size: 16px;

    &:first-child {
      color: var(--color-tip);
      font-size: 14px;
    }

    & + & {
      div {
        display: inline-block;
        width: 50%;
      }
    }
  }

  .l-img {
    margin-left: 33px;
  }
</style>

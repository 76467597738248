<template>
  <div>
    <NavHeader />
    <NavHeaderMobile />
    <Banner />
    <div class="g-container wrapper">
      <Deploy />
      <Customize />
    </div>
    <Footer />
  </div>
</template>

<script>
import NavHeader from '@/components/NavHeader.vue';
import NavHeaderMobile from '@/components/NavHeaderMobile.vue';
import Banner from '@/views/BusinessCard/Banner.vue';
import Deploy from '@/views/BusinessCard/Deploy.vue';
import Customize from '@/views/BusinessCard/Customize.vue';
import Footer from '@/views/OnlinePayment/Footer.vue';

export default {
  name: 'BusinessCard',
  components: {
    Footer,
    Customize,
    Deploy,
    Banner,
    NavHeader,
    NavHeaderMobile,
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  overflow: hidden;
}
</style>
